import { FC, useState } from "react";
import styles from './UserBlock.module.css';
import Icon from "@ant-design/icons";
import { Block } from "../../assets/icons/Block";
import { useAppSelector } from "../../store";
import { mailto_support, recovery_form, send_form_template, send_support_button } from "../../utils";
import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

export const UserBlock: FC = () => {
  const { blockData, _phone } = useAppSelector(({ userSlice }) => userSlice);

  const [isSendFormOpen, setIsSendFormOpen] = useState<boolean>(false);
  const [sendFormText, setSendFormText] = useState<string>('');

  const form_text = `${mailto_support}\n${_phone}\n${sendFormText}\n${send_form_template}`;

  return (
    <div className={styles._container}>
      <div className={styles._body}>
        <Icon component={Block}></Icon>
        <div className={styles._text}>
          <h2 className='auth__title_block'>{blockData?.header}</h2>
          <p>{blockData?.title}</p>
          <div className={styles.block_line}></div>
          <p className={styles.block_footer}>{blockData?.footer} <br /><Button type="link" onClick={() => setIsSendFormOpen(!isSendFormOpen)}>{recovery_form}</Button></p>
          {isSendFormOpen &&
            <div>
              <TextArea onChange={(e) => setSendFormText(e.target.value)} />
              <Button className={styles.button_send_form} type="primary">
                <a href={form_text}>{send_support_button}</a>
              </Button>
            </div>}
        </div>
      </div>
    </div>
  )
}
