import React, { FC } from 'react';
import styles from './LogWithPlatform.module.css'
import { Button } from "antd";
import { useAppDispatch } from "../../../store";
import { loginWithLeaderId } from "../../../store/actions/userAction";
import { LeaderIdIcon } from '../../../assets/icons/LeaderIdIcon';

const LogWithPlatform: FC = () => {
  const dispatch = useAppDispatch()

  return (
    <>
      <Button
        onClick={() => dispatch(loginWithLeaderId())}
        className={styles.button}
      >
        <span className={styles.text}>Войти с помощью</span>
        <LeaderIdIcon />
      </Button>
    </>
  );
};

export default LogWithPlatform;